var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"title-content"},[_c('BreadCrumb',[_c('el-breadcrumb-item',{attrs:{"to":{ name: 'homePage' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v(" 印花税测算")])],1),_c('div',{staticClass:"main-top"},[_c('MainHeader',[_c('template',{slot:"title"},[_vm._v("印花税申报表上传")])],2),_c('SaSteps',{attrs:{"steps":_vm.steps,"active-index":_vm.stepActive}}),_c('div',{staticClass:"placeholder"})],1)],1),_vm._m(0),_c('div',{staticClass:"section-box"},[_c('div',{staticClass:"title"},[_vm._v(" 二.请选择需要导入的文件 ")]),_c('GeneralUploader',{attrs:{"upload-url":_vm.uploadUrl,"param-name":"files"},on:{"complete":_vm.handleUploaderComplete}},[_c('div',{staticClass:"dz-message"},[_c('img',{staticClass:"drog_img",attrs:{"src":require("@/assets/test/uploader@2x.png"),"alt":""}}),_c('div',{staticClass:"drog_text"},[_c('span',{staticClass:"drog_text-blue"},[_vm._v("点击上传, 或将文件拖拽到此处")])]),_c('span',{staticClass:"acceptable_text"},[_vm._v("支持：Excel/HTML/文件夹")])])])],1),_c('div',{staticClass:"section-box"},[_c('div',{staticClass:"title"},[_vm._v(" 三.数据预览 ")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.previewTable,"stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"180"}}),_c('el-table-column',{attrs:{"prop":"excelTableNameCn","label":"表名"}}),_c('el-table-column',{attrs:{"prop":"zqZW","label":"税款所属期间"}}),_c('el-table-column',{attrs:{"prop":"contractNo","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"manipulator-btn",attrs:{"to":{
                  name: 'ViewYinhuashuiTable',
                  params: {
                    templateID: scope.row.excelTableName,
                    zq: scope.row.zq
                  },
                  query:{
                    name:`${scope.row.excelTableNameCn} ${scope.row.zqZW}`,
                    redirectTo: _vm.$route.fullPath
                  }
                }}},[_vm._v("查看 ")]),_c('button',{staticClass:"btn btn-link manipulator-btn",on:{"click":function($event){return _vm.handleBtnRemove(scope.row.zqZW,scope.row.excelTableName,scope.row.zq)}}},[_vm._v("删除 ")])]}}])})],1)],1),_c('SaFooter',[_c('button',{staticClass:"btn btn-purge-data",on:{"click":_vm.handleBtnPurgeDataClicker}},[_vm._v(" 清除数据 ")]),_c('AwaitButton',{staticClass:"btn btn-primary btn-next",on:{"click":_vm.handleBtnNextClick}},[_vm._v(" 立即测算 ")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-box"},[_c('div',{staticClass:"title"},[_vm._v(" 一.请按照数据模板的格式准备要导入的数据。"),_c('a',{staticClass:"tips",attrs:{"href":"https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E5%8D%B0%E8%8A%B1%E7%A8%8E%E7%BA%B3%E7%A8%8E%E7%94%B3%E6%8A%A5%E8%A1%A8.xlsx","target":"_blank"}},[_vm._v("点击下载《印花税申报表模板》")]),_c('a',{staticClass:"tips",attrs:{"href":"https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E8%B4%A2%E4%BA%A7%E5%92%8C%E8%A1%8C%E4%B8%BA%E7%A8%8E%E7%BA%B3%E7%A8%8E%E7%94%B3%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx","target":"_blank"}},[_vm._v("点击下载《财产和行为税纳税申报表模板》")])]),_c('div',{staticClass:"guide"},[_c('div',[_vm._v("建议通过电子税务局下载后上传，推荐完整上传近三年的印花税申报表或财产和行为税纳税申报表")])])])
}]

export { render, staticRenderFns }