<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item> -->

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">印花税申报表上传</template>
        </MainHeader>
        <SaSteps :steps="steps" :active-index="stepActive"></SaSteps>
        <div class="placeholder"></div>
      </div>
    </div>
    <div class="section-box">
      <div class="title">
        一.请按照数据模板的格式准备要导入的数据。<a
        href="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E5%8D%B0%E8%8A%B1%E7%A8%8E%E7%BA%B3%E7%A8%8E%E7%94%B3%E6%8A%A5%E8%A1%A8.xlsx"
        target="_blank" class="tips">点击下载《印花税申报表模板》</a>

        <a
          href="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E8%B4%A2%E4%BA%A7%E5%92%8C%E8%A1%8C%E4%B8%BA%E7%A8%8E%E7%BA%B3%E7%A8%8E%E7%94%B3%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx"
          target="_blank" class="tips">点击下载《财产和行为税纳税申报表模板》</a>



      </div>
      <div class="guide">
        <div>建议通过电子税务局下载后上传，推荐完整上传近三年的印花税申报表或财产和行为税纳税申报表</div>
      </div>
    </div>
    <div class="section-box">
      <div class="title">
        二.请选择需要导入的文件
      </div>
      <GeneralUploader :upload-url="uploadUrl" param-name="files"
                       @complete="handleUploaderComplete">
        <div class="dz-message">
          <img class="drog_img" src="@/assets/test/uploader@2x.png" alt/>
          <div class="drog_text">
            <span class="drog_text-blue">点击上传, 或将文件拖拽到此处</span>
          </div>
          <span class="acceptable_text"
          >支持：Excel/HTML/文件夹</span
          >
        </div>
      </GeneralUploader>
    </div>
    <div class="section-box">
      <div class="title">
        三.数据预览
      </div>
      <el-table
        :data="previewTable"
        stripe
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="excelTableNameCn"
          label="表名"
        >
        </el-table-column>
        <el-table-column
          prop="zqZW"
          label="税款所属期间"
        >
        </el-table-column>
        <el-table-column
          prop="contractNo"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <router-link
              :to="{
                    name: 'ViewYinhuashuiTable',
                    params: {
                      templateID: scope.row.excelTableName,
                      zq: scope.row.zq
                    },
                    query:{
                      name:`${scope.row.excelTableNameCn} ${scope.row.zqZW}`,
                      redirectTo: $route.fullPath
                    }
                  }"
              class="manipulator-btn"
            >查看
            </router-link>
            <button
              @click="handleBtnRemove(scope.row.zqZW,scope.row.excelTableName,scope.row.zq)"
              class="btn btn-link manipulator-btn"
            >删除
            </button>
          </template>
        </el-table-column>

      </el-table>


    </div>
    <SaFooter>
      <button
        class="btn btn-purge-data"
        @click="handleBtnPurgeDataClicker"
      >
        清除数据
      </button>
      <AwaitButton
        class="btn btn-primary btn-next"
        @click="handleBtnNextClick"
      >
        立即测算
      </AwaitButton>
    </SaFooter>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";
import GeneralUploader from "../../components/GeneralUploader";
import {
  clearYinhuashuiData, delReportDataInfo,
  getContDetailList,
  getUploadExcelEx3Url,
  getUploadYinhuashuiUrl, getYHSList, inspectCommit,
  uploadContExcelByStamp
} from "../../../api/yinhuashui";
import AwaitButton from "await-button";

export default {
  name: "YinHuashui",
  props: {
    programId: String
  },
  mounted() {
    this.loadListData()
  },
  components: {
    AwaitButton,
    SaFooter,
    BreadCrumb,
    MainHeader,
    SaSteps,
    GeneralUploader
  },
  data() {
    return {
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 2,
      form: {
        companyName: '',
        nsrsbh: '',

      },
      previewTable: []
    }
  },
  methods: {
    //删除文件
    handleBtnRemove(filename, templateId, zq) {
      const _this = this;
      this.$confirm(`${filename} 将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const uuid = this.$route.params.uuid;
          const result = await delReportDataInfo({
              zq,
              programId: this.programId,
              excelTableName: templateId
            }
          );


          if (result.code == 200) {
            this.loadListData();
            _this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            _this.$message.error(result.message);
          }
        })
    },
    //获取印花税申报表列表
    async loadListData() {
      const res = await getYHSList({programId: this.programId})
      this.previewTable = res.data
    },
    handleUploaderComplete(files) {
      const total = files.length;

      let success = 0;
      for (const fileGrp of files) {
        for (const file of fileGrp) {
          if (file.status == "success") {
            success++
          }
        }
      }

      const failure = total - success;
      let msg = `共上传${total}个文件,成功${success}个`;
      if (failure > 0) {
        msg += `,失败${failure}个`
      }
      this.$alert(msg, "提示", {
        confirmButtonText: "确定",
        type: "info"
      });

      this.loadListData();
    },
    async handleBtnNextClick() {
      const res = await inspectCommit({programId: this.programId});
      if (res.code == 200) {
        this.$router.push({name: 'stampTax-done'})
      }

    },
    //清除数据
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearYinhuashuiData({
          programId: this.programId
        });

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadListData();
        } else {
          this.$message.error(result.message);
        }
      });
    },
  },
  computed: {

    uploadUrl() {
      return getUploadYinhuashuiUrl(this.programId)
    },
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;

      .tips {
        display inline-block;
        font-size: 14px;
        color: #f5222d;
        margin-left: 16px;
      }
    }

    .guide {
      margin-top 20px
      line-height 2

    }

  }

  .btn-purge-data {
    color: #ff6464;
  }

  .table {
    border-collapse: collapse;
    box-shadow: 0px -1px 0px 0px #EEEEEE;
    background #fff;
    width 100%

    td, th {
      padding 5px 5px 5px 12px
      text-align left
      border-right 1px solid #D9D9D9;
      border-bottom 1px solid #EEEEEE;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
    }

    td:last-child, th:last-child {
      border-right none;
    }

    thead {
      background: #F0F5FF;
    }

    th {
      color: rgba(0, 0, 0, 0.8);
      font-weight bold
    }

    .summary {
      background: #F0F5FF;
    }

    .input {
      border 1px solid #ccc;
      display block
      line-height 2
      width calc(100% - 12px)
      border-radius 5px
      padding 0 10px
      box-sizing border-box
    }

    .input {
      outline none
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .manipulator-btn {
    color: rgba(47, 84, 235, 1);
    background: none;
    border: 0 none;
    cursor: pointer;
    outline-style: none;
    box-shadow: none;
    display inline-block;
    padding 0 12px;
    padding-left 0;
  }

  .manipulator-btn:hover {
    text-decoration: underline;
  }

}
</style>
